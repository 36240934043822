body {
  margin: 0;
  background-color: #cccccc;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img.topLogo {
  background-color: #ffffff;
  border-radius: 16px;
}

input[type="file"]::file-selector-button {
  border-radius: 8px;
  border: 1.0px solid rgba(34, 124, 255, 0.5);
  background-color: #ffffff;
  height: 34.5px;
  padding: 0 16px;
  margin-right: 16px;
  text-transform: uppercase;
  color: #227cff;
}